import React from 'react';
import {css} from 'emotion';
import Styled from 'react-emotion';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Row from './Row'
import DownloadAppButton from './appComponents/DownloadAppButton';
import DownloadAppButtonMobile from './appComponents/DownloadAppButtonMobile';


/* global tw */

const styles = {
    headerLogo: css`
        height: 6vh;
        @media (min-width: 992px){
            height: 10vh;
        }
    `,
    menuList: css`
        ${tw`list-reset block`};

    `,
    menuListItem: css`
        ${tw`hidden lg:inline-flex items-center lg:mx-4 relative `};

    `,
}

const NavBar = (props) => (
    <div className={css`${tw`w-screen overflow-hidden absolute pin-t py-4 z-100 bg-transparent`}`}>
        <div className={css`${tw`mx-auto -mt-2 max-w-row w-full flex items-center justify-between flex-wrap px-4 lg:px-1`}`}>

            <img className={styles.headerLogo}
                    src={require('../assets/img/logo-with-text.svg')}
                    alt="Zyppys Logo"/>

           <DownloadAppButton dark hideOnMobile/>
           <DownloadAppButtonMobile hide/>
        </div>
    </div>
);


export default NavBar;